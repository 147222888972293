import { useContext, useState } from 'react';
import { LoadingSpinner, Modal } from '@rabbit/elements/shared-components';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import CreateNewConsumerForm from '../forms/CreateNewConsumerForm/CreateNewConsumerForm';
import CreateNewClaimUpload from '../forms/CreateNewClaimUpload/CreateNewClaimUpload';
import NewClaimCreateHoldingForm from '../forms/NewClaimCreateHoldingForm/NewClaimCreateHoldingForm';
import { toast } from 'react-toastify';
import CreateNewSelectUser from '../forms/CreateNewSelectUser/CreateNewSelectUser';
import { CreateNewChooseProduct } from '../forms/CreateNewChooseProduct/CreateNewChooseProduct';
import { ConfigContext } from '@rabbit/config/context';
import { useTranslation } from 'react-i18next';
import { DTHoldingProxy, DTConsumer_Public } from '@rabbit/data/types';

export interface ModalNewClaimProps {
  data?: {
    holding: DTHoldingProxy;
    consumer: DTConsumer_Public;
  } | null;
  handleClose: () => void;
}

export type NewClaimModalSteps =
  | 'select-user'
  | 'choose-product'
  | 'add-product'
  | 'new-consumer'
  | 'claim-upload'
  | 'add-new-product';

interface CurrCaseShape {
  case_id: string;
  consumer_persona_id?: string;
  holding_id?: string;
}

function ModalNewClaim({ handleClose, data }: ModalNewClaimProps) {
  const { config } = useContext(ConfigContext);
  const [currCase, setCurrCase] = useState<CurrCaseShape | null>(null);
  const [currentStep, setCurrentStep] = useState<{
    step: NewClaimModalSteps;
    data: any;
  }>({ step: data ? 'add-product' : 'select-user', data: data ?? null });
  const { t } = useTranslation();

  const modalSettings: ModalSettingsShape = {
    title: t('general.newClaim'),
    handleClose: handleClose,
    childrenClassName:
      currentStep.step === 'select-user' ? 'overflow-visible' : 'overflow-auto',
  };

  const onChangeStep = (step_in: NewClaimModalSteps, data: any = null) => {
    setCurrentStep({ ...currentStep, step: step_in, data: data });
  };

  const [loading, setLoading] = useState(false);
  // Holding created and show the next step: Upload Proof of Purchase
  const holdingCreated = (result: CurrCaseShape) => {
    setCurrCase(result);
    modalSettings.title = 'Supporting materials';
    toast.success('Claim created successfully.');
  };

  if (!config) return <LoadingSpinner size="xs" />;

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      isLoading={
        loading &&
        (currentStep.step === 'add-product' ||
          currentStep.step === 'select-user')
      }
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      {currentStep.step === 'select-user' && (
        <CreateNewSelectUser
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
          setLoading={setLoading}
          kind="new-claim"
        />
      )}
      {currentStep.step === 'choose-product' && (
        <CreateNewChooseProduct
          changeStep={onChangeStep}
          handleClose={handleClose}
          data={currentStep.data}
          setLoading={setLoading}
          onHoldingCreated={holdingCreated}
        />
      )}
      {currentStep.step === 'new-consumer' && (
        <CreateNewConsumerForm
          onChange={onChangeStep}
          data={currentStep.data}
          handleClose={handleClose}
        />
      )}
      {currentStep.step === 'add-product' && (
        <NewClaimCreateHoldingForm
          onChange={onChangeStep}
          data={data || currentStep.data}
          handleClose={handleClose}
          setLoading={setLoading}
          onHoldingCreated={holdingCreated}
          kind={config.CLAIMS.CLAIMS_FLOW.CREATE_HOLDING_FORM}
        />
      )}
      {currentStep.step === 'claim-upload' && currCase && (
        <CreateNewClaimUpload
          persona_id={
            currentStep.data?.consumer_persona_id ||
            currCase.consumer_persona_id
          }
          holding_id={currentStep.data?.holding_id || currCase.holding_id}
          case_id={currCase.case_id}
          handleClose={handleClose}
        />
      )}
    </Modal>
  );
}

export default ModalNewClaim;
