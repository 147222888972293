import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  ButtonIcon,
  CloudUploadIcon,
  Input,
  SkeletonInputGrid,
  Toggle,
  TrashIcon,
} from '@rabbit/elements/shared-components';
import {
  FileStorageContext,
  VendableEditorContext,
} from '@rabbit/bizproc/react';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import {
  AddEditVendableFormValuesShape,
  DocTypeShapeTypes,
  FileUploaderVariations,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import {
  DTVendable,
  DTVendable_Private,
  DTWarranty_Template,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  UserUploadedDocument,
  VendableFull,
} from '@rabbit/data/types';
import { t } from 'i18next';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuid } from 'uuid';
import { ConfigContext } from '@rabbit/config/context';

export interface ModalAddEditVendableProps {
  handleClose: () => void;
  //handleDelete?: () => void;
  vendableId?: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  data?: any;
  onChangeStep?: (step_label: string, data: any) => void;
}

export function ModalAddEditVendableV2({
  handleClose,
  //handleDelete,
  setLoading,
  vendableId,
  data,
  onChangeStep,
}: ModalAddEditVendableProps) {
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);
  const activePremiumTenant = t('tenantLink');
  const appInfo = useAppInfo();
  const formikRef = useRef<FormikProps<AddEditVendableFormValuesShape> | null>(
    null
  );
  const { config } = useContext(ConfigContext) || {};
  const isUniqueProductActivated =
    config.HOLDINGS.NEW_REGISTRATION_FLOW.UNIQUE_PRODUCT;
  const newVendableId = !vendableId
    ? `${activePremiumTenant}_${uuid()}`
    : undefined;

  const contextValues = useContext(VendableEditorContext);
  const {
    createOrUpdateVendable,
    getSingleVendable,
    getWarrantyTemplates,
    setShouldRefresh,
  } = contextValues;
  const {
    unusedCompletedCleanup,
    moveCompletedUploadsToAttached,
    deleteFile,
    uploadQueueState,
  } = useContext(FileStorageContext) || {};

  const [vendableData, setVendableData] = useState<VendableFull | null>(null);
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
  const [warrantyTemplates, setWarrantyTemplates] = useState<
    DTWarranty_Template[]
  >([]);
  const pendingSubmissionRef = useRef<AddEditVendableFormValuesShape | null>(
    null
  );
  const warrantyPlanOptions =
    generateWarrantyTemplateOptions(warrantyTemplates);

  /* ------------------------------- Fetch data ------------------------------- */
  useEffect(() => {
    if (vendableId && getSingleVendable) {
      getSingleVendable(vendableId).then((data) => {
        setVendableData(data);
        setIsImageUploaded(!!data?.pub?.images?.length);
      });
    }

    getWarrantyTemplates().then((data) => {
      setWarrantyTemplates(data);
    });
  }, [vendableId]);

  /* -------------------- Clean up unused completed uploads ------------------- */
  useEffect(() => {
    return () => {
      if (unusedCompletedCleanup) {
        void unusedCompletedCleanup();
      }
    };
  }, []);

  useEffect(() => {
    if (pendingSubmissionRef.current) {
      const values = pendingSubmissionRef.current;
      pendingSubmissionRef.current = null; // Clear pending reference
      onSubmit(values); // Proceed with submission
    }
  }, [uploadQueueState]);

  /* -------------------------------------------------------------------------- */
  /*                              Handle submission                             */
  /* -------------------------------------------------------------------------- */
  const onSubmit = async (values: AddEditVendableFormValuesShape) => {
    // console.log('values', values);
    setLoading(true);
    if (uploadQueueState?.ongoing.length) {
      pendingSubmissionRef.current = values; // Queue the submission
      return;
    }
    let uploadedFiles = values.vendableImages;
    if (uploadQueueState?.completed.length)
      uploadedFiles = uploadQueueState?.completed.map(
        (file) => file.uploadedFile
      ) as UserUploadedDocument[];
    const pubVendable: Partial<DTVendable> = {
      title: values.title,
      // We'll not be doing any differentiation between title and full title for now
      full: values.title,
      detail: values.detail,
      brand: values.brand,
      img: isImageUploaded
        ? uploadedFiles?.map((image) => image?.url as string)
        : [],
      images: isImageUploaded ? uploadedFiles : [],
      upc: values.upc,
      mpn: values.sku,
      unique: isUniqueProductActivated ? values.unique : false,
      mfr:
        PersonaTypeSingleLetter.Manufacturer +
        PersonaIdTypeSplitter +
        activePremiumTenant,
      category: values.category ?? [], // TODO: integrate full category system
      keywords: values.keywords,
      defaultTemplate: values.warrantyTemplate,
      deprecated: values.deprecated === 'true' ? true : false,
      manual: values.manual,
      published: values.published,
    };
    const rrp = {
      amount: Number(values.rrp?.amount) ?? 0,
      currency: values.rrp?.currency ?? 'AUD',
    };

    const privVendable: Partial<DTVendable_Private> = {
      mfr:
        PersonaTypeSingleLetter.Manufacturer +
        PersonaIdTypeSplitter +
        activePremiumTenant,
      rrp: rrp,
      published: values.published,
      deprecated: values.deprecated === 'true' ? true : false,
    };
    try {
      const payload = {
        publicData: pubVendable,
        privateData: privVendable,
        vendableId,
        newVendableId,
      };
      const docId = await createOrUpdateVendable(payload);
      if (!onChangeStep) {
        setShouldRefresh(6000);
      }

      moveCompletedUploadsToAttached?.(uploadQueueState?.completed ?? []);
      // delete all attached vendable image files that aren't the new one.
      // (manual can have multiple)
      // This is to prevent orphaned files in the file storage
      // when we enable multiple images per vendable this needs to be reviewed
      if (vendableData?.pub.images && values.vendableImages.length > 0) {
        const filesToBeCleared = vendableData.pub.images.filter(
          (file) => file.url !== values.vendableImages[0].url
        );
        if (filesToBeCleared.length > 0 && deleteFile) {
          for (const file of filesToBeCleared) {
            void deleteFile(file.url, {
              category: UploadedFileCategories.VendableImages,
            });
          }
        }
      }
      toast.success(t('message.productRegisteredSuccessfully'));
      if (!onChangeStep) {
        handleClose();
      }
      if (onChangeStep) {
        onChangeStep('add-product', {
          ...data,
          productResponse: {
            id: docId,
            label: values.title + ' (' + values.sku + ')',
            value: docId,
          },
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(
        `${t('Something went wrong while')} ${
          newVendableId ? t('creating') : t('editing')
        } ${t('the product! Please try again later.')}`
      );
    } finally {
      pendingSubmissionRef.current = null;
      setLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Form setup                                 */
  /* -------------------------------------------------------------------------- */

  // These are the fields we will be going with for the editor. For non-Shopify based tenants,
  // they will all be editable. For Shopify based ones, however, some will be read-only, and
  // some will be editable

  const schema = Yup.object().shape({
    title: Yup.string().required(t('message.pleaseEnterATitle')),
    detail: Yup.string(),
    brand: Yup.string().required(t('message.pleaseEnterABrand')),
    upc: Yup.string().required(t('message.pleaseEnterUPC')),
    sku: Yup.string().required(t('Required')),
    category: Yup.array().min(1).required(t('message.pleaseSelectACategory')),
    // disabled for first release -dc
    // keywords: Yup.array(),
    warrantyTemplate: Yup.string().required(t('Required')),
    rrp: Yup.object({
      amount: Yup.number().required(t('Required')),
      currency: Yup.string().required(t('Required')),
    }),
    manual: Yup.array(),
    // todo type this to UserUploadedDocument[] -dc
    vendableImages: Yup.array(),
    published: Yup.boolean().required(t('Required')),
    unique: Yup.boolean().default(false),
    // this is later forced into boolean, but has to be string to due to some weird input behaviours
    deprecated: Yup.string().required(t('Required')),
  });

  const initialValues: AddEditVendableFormValuesShape = {
    title: vendableData?.pub.title ?? '',
    brand: vendableData?.pub?.brand ?? '',
    detail: vendableData?.pub?.detail ?? '',
    upc: vendableData?.pub?.upc ?? '',
    sku: vendableData?.pub?.mpn ?? '',
    // todo these need to match the category system -dc
    category: vendableData?.pub.category ?? [],
    keywords: vendableData?.pub.keywords ?? [],
    warrantyTemplate: vendableData?.pub.defaultTemplate ?? '',
    rrp: vendableData?.priv.rrp ?? { amount: 0, currency: 'AUD' },
    manual: vendableData?.pub.manual ?? [],
    vendableImages: vendableData?.pub.images ?? [],
    published: vendableData?.pub.published ?? false,
    unique: vendableData?.pub.unique
      ? vendableData?.pub.unique
      : onChangeStep
      ? true
      : false,
    deprecated: vendableData?.pub.deprecated ? 'true' : 'false',
  };

  // Ref controls for the file uploader input
  const selectFileRef = useRef<HTMLElement>();
  const removeFileRef = useRef<HTMLElement>();

  /* -------------------------------------------------------------------------- */
  /*                                     TSX                                    */
  /* -------------------------------------------------------------------------- */
  if (!newVendableId && !vendableData)
    return (
      <div className="p-5">
        {/** This is pretty unique to this modal, but if used again extract to a separate component */}
        <div className="mb-5 grid grid-cols-4 gap-2 rounded-md border border-gray-200 p-2">
          <div className="col-span-1">
            <Skeleton count={1} className="py-14" />
          </div>
          <div className="col-span-3 py-3 ">
            <div className="mb-4 grid grid-cols-2 gap-2">
              <Skeleton count={1} className="py-2" />
              <Skeleton count={1} className="!w-1/2 py-2" />
            </div>
            <Skeleton count={1} className="!w-1/3 py-2" />
          </div>
          <div className="col-span-4">
            <Skeleton count={1} className="py-3" />
          </div>
        </div>
        <SkeletonInputGrid cols={2} rows={5} />
      </div>
    );

  const getCategories = () =>
    config.HOLDINGS.PRODUCT.CUSTOM_CATEGORY
      ? SaezCategories
      : isUniqueProductActivated
      ? AbbysJeweleryCategories
      : SheltaCategories;

  return (
    <div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={validateOnChange}
        validateOnBlur={false}
      >
        {(props) => {
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                props.validateForm();
                setValidateOnChange(true);
                props.handleSubmit(e);
              }}
            >
              <div className="flex flex-col gap-4 px-4 pt-4">
                <div className="flex flex-col gap-4 rounded-lg border border-gray-200 p-2">
                  <div className="flex justify-start gap-4">
                    <div>
                      <SageFileUploader
                        variation={FileUploaderVariations.InputPicture}
                        name={'vendableImages'}
                        identifiers={{
                          personaId:
                            PersonaTypeSingleLetter.Manufacturer +
                            PersonaIdTypeSplitter +
                            activePremiumTenant,
                          category: UploadedFileCategories.VendableImages,
                          docType: {
                            type: DocTypeShapeTypes.Vendable,
                            docid: vendableId ?? newVendableId ?? null,
                          },
                        }}
                        currentFiles={props.values.vendableImages ?? []}
                        refs={{ selectFileRef, removeFileRef }}
                        maxFiles={1}
                      />
                    </div>
                    <div className="flex flex-col gap-4 py-4">
                      <div className="flex gap-2">
                        <div className="text-lg font-bold">
                          {vendableData?.pub.title ?? 'Product name'}
                        </div>
                        <div>
                          {vendableData?.pub.published ? (
                            <div className="inline overflow-hidden rounded bg-green-100 py-1 px-2">
                              {t('Published')}
                            </div>
                          ) : (
                            <div className="inline overflow-hidden rounded bg-gray-100 py-1 px-2">
                              {t('Unpublished')}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        {props.values.vendableImages.length === 0 && (
                          <ButtonIcon
                            kind="bgLightGreen"
                            onClick={() => {
                              selectFileRef.current?.click();
                              setIsImageUploaded(true);
                            }}
                            label={t('Upload image')}
                            Icon={CloudUploadIcon}
                            iconLeft
                          />
                        )}
                        {props.values.vendableImages.length > 0 && (
                          <ButtonIcon
                            kind="bgRed"
                            onClick={() => {
                              removeFileRef.current?.click();
                              setIsImageUploaded(false);
                            }}
                            label={t('Remove image')}
                            Icon={TrashIcon}
                            iconLeft
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full items-center gap-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-2">
                    <div className="grow">{t('Publish product')}</div>
                    <Toggle
                      name="publish"
                      checked={props.values.published}
                      onChange={() => {
                        props.setFieldValue(
                          'published',
                          !props.values.published
                        );
                      }}
                    />
                  </div>
                  {isUniqueProductActivated && (
                    <div className="flex w-full items-center gap-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-2">
                      <div className="grow">{t('Unique product')}</div>
                      <Toggle
                        name="unique"
                        checked={props.values.unique || false}
                        onChange={() => {
                          props.setFieldValue('unique', !props.values.unique);
                        }}
                        disabled={!!onChangeStep}
                      />
                    </div>
                  )}
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <Input
                      type="text"
                      label={t('Product name') + '*'}
                      name="title"
                      settings={{
                        hint: '*' + t('required'),
                        placeholder: t('Product name'),
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      type="text"
                      label={t('Brand') + '*'}
                      name="brand"
                      settings={{
                        hint: '*' + t('required'),
                        placeholder: t('Brand name'),
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    {/* Meanwhile, we use this one */}
                    <Input
                      type="select"
                      label={t('Category') + '*'}
                      name="category"
                      settings={{
                        isMulti: true,
                        options: getCategories(),
                        placeholder: t('general.pleaseSelectACategory'),
                        id: 'category',
                        errors: props.errors,
                        hint: '*' + t('required'),
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      type="text"
                      label={t('SKU') + '*'}
                      name="sku"
                      settings={{
                        hint: '*' + t('required'),
                        placeholder: t('SKU'),
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <Input
                      type="text"
                      label={t('UPC') + '*'}
                      name="upc"
                      settings={{
                        hint: '*' + t('required'),
                        placeholder: t('UPC'),
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      type="select"
                      label={t('Warranty plan') + '*'}
                      name="warrantyTemplate"
                      settings={{
                        options: warrantyPlanOptions,
                        placeholder: t('message.pleaseSelectAnOption'),
                        id: 'warranty_plan',
                        errors: props.errors,
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <Input
                      type="currency"
                      label={t('Price')}
                      name="rrp"
                      settings={{
                        id: 'rrp',
                        currency:
                          props?.values?.rrp?.currency ?? appInfo.currency,
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      type="select"
                      label={t('Deprecated')}
                      name="deprecated"
                      settings={{
                        // For some reason I can never assign booleans or falsy values here.
                        // They'll turn into an empty string instead of false, or 0, or whatever
                        // So I'm using "true" and "false" instead.
                        options: [
                          { value: 'true', label: t('Yes') },
                          { value: 'false', label: t('No') },
                        ],
                        placeholder: t('Deprecated'),
                        id: 'deprecated',
                        errors: props.errors,
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <Input
                    type="rich-text"
                    label={t('Product description')}
                    name="detail"
                    settings={{}}
                  />
                </div>
                <SageFileUploader
                  variation={FileUploaderVariations.FormItem}
                  name={'manual'}
                  label={t('Product manuals')}
                  identifiers={{
                    personaId:
                      PersonaTypeSingleLetter.Manufacturer +
                      PersonaIdTypeSplitter +
                      activePremiumTenant,
                    category: UploadedFileCategories.VendableManuals,
                    docType: {
                      type: DocTypeShapeTypes.Vendable,
                      docid: vendableId ?? newVendableId ?? null,
                    },
                  }}
                  currentFiles={props.values.manual ?? []}
                />
                <div className="sticky bottom-0 bg-white py-2">
                  <Button
                    kind="primary"
                    type="submit"
                    children={t('Save')}
                    disabled={!props.isValid || !props.dirty}
                    className="w-full"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

function generateWarrantyTemplateOptions(
  warrantyTemplates: DTWarranty_Template[]
) {
  return warrantyTemplates.map((template) => ({
    id: template.orderNo,
    value: template.docid,
    label: template.title,
  }));
}

const getValidCategories = (categories: string[]): string[] => {
  if (!categories || !Array.isArray(categories)) return [];
  const validCategories = categories
    .filter((category) =>
      SheltaCategories.some(
        (sheltaCategory) => sheltaCategory.value === category
      )
    )
    .map((category) => {
      const matchingCategory = SheltaCategories.find(
        (sheltaCategory) => sheltaCategory.value === category
      );
      return matchingCategory ? matchingCategory.label : undefined;
    })
    .filter((label): label is string => label !== undefined);

  return validCategories.length > 0 ? validCategories : [];
};

// Remove this when the category system is implemented
const SheltaCategories = [
  { label: 'Fashion and lifestyle', value: 'fashion-and-lifestyle' },
  { label: 'Clothing', value: 'clothing' },
  { label: 'Clothing accessories', value: 'clothing-accessories' },
  { label: 'Umbrellas', value: 'umbrellas' },
  { label: 'Home', value: 'home' },
  { label: 'Garden and patio', value: 'garden-and-patio' },
  {
    label: 'Outdoor and patio furniture',
    value: 'outdoor-and-patio-furniture',
  },
  { label: 'Patio umbrellas', value: 'patio-umbrellas' },
  { label: 'Generic', value: 'generic' },
  { label: 'Sports and recreation', value: 'sports-and-recreation' },
  { label: 'Camping, tourism, outdoor', value: 'camping-tourism-outdoor' },
  { label: 'Beach umbrellas', value: 'beach-umbrellas' },
];

const AbbysJeweleryCategories = [
  { label: 'Rings', value: 'rings' },
  { label: 'Bridal Rings', value: 'bridal-rings' },
  { label: 'Engagement Rings', value: 'engagement-rings' },
  { label: 'Ladies Diamond Rings', value: 'ladies-diamond-rings' },
  { label: 'Ladies Diamond Bands', value: 'ladies-diamond-bands' },
  { label: 'Men’s Diamond Rings', value: 'mens-diamond-rings' },
  { label: 'Men’s Diamond Bands', value: 'mens-diamond-bands' },
  { label: 'Wraps and Enhancers', value: 'wraps-and-enhancers' },
  { label: 'Stackables', value: 'stackables' },
  { label: 'Trio Sets', value: 'trio-sets' },
  { label: 'Bracelets', value: 'bracelets' },
  { label: 'Ladies Diamond Bracelets', value: 'ladies-diamond-bracelets' },
  { label: 'Men’s Diamond Bracelets', value: 'mens-diamond-bracelets' },
  { label: 'Earrings', value: 'earrings' },
  { label: 'Ladies Diamond Earrings', value: 'ladies-diamond-earrings' },
  { label: 'Studs', value: 'studs' },
  { label: 'Hoops', value: 'hoops' },
  { label: 'Necklaces', value: 'necklaces' },
  { label: 'Ladies Diamond Necklaces', value: 'ladies-diamond-necklaces' },
  { label: 'Men’s Diamond Necklaces', value: 'mens-diamond-necklaces' },
  { label: 'Pendants', value: 'pendants' },
  { label: 'Ladies Diamond Pendants', value: 'ladies-diamond-pendants' },
  { label: 'Men’s Crosses & Charms', value: 'mens-crosses-charms' },
  { label: 'Color Stones', value: 'color-stones' },
  { label: 'Tanzanite', value: 'tanzanite' },
  { label: 'Morganite', value: 'morganite' },
];

const SaezCategories = [
  { label: 'Air Distribution', value: 'air-distribution' },
  { label: 'Motor Accessories', value: 'motor-accessories' },
  { label: 'HVAC Equipment', value: 'hvac-equipment' },
  { label: 'Testing & Tools', value: 'testing-tools' },
  { label: 'HVAC Parts', value: 'hvac-parts' },
  { label: 'Installation', value: 'installation' },
  { label: 'Literature', value: 'literature' },
  { label: 'Motors', value: 'motors' },
  { label: 'Refrigeration', value: 'refrigeration' },
  { label: 'Supplies', value: 'supplies' },
  { label: 'Fire Detection Parts', value: 'fire-detection-parts' },
  { label: 'Thermostats', value: 'thermostats' },
  { label: 'Ventilation & IAQ', value: 'ventilation-iaq' },
];
