import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  LoadingSpinner,
  getFormattedDate,
  getAgeFromDate,
  getCurrencyFormat,
  getIncreasedLabourRateValue,
  getConvertedMileage,
} from '@rabbit/elements/shared-components';
import { toTitleCase, useAppInfo } from '@rabbit/sage/utils/helpers';
import {
  CAR_FUEL_ELECTRIC,
  CAR_FUEL_HYBRID,
  DRIVE_TYPE_4X4,
  useSageAPI,
} from '@rabbit/bizproc/react';
import {
  DTOptionsSingleOption,
  DTWarranty,
  DTWarranty_Offer,
  DTWarranty_Template,
  DeciderOutput,
  PartnerSettings,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  SRVInfo,
  SRVType,
  SingleApprovedOptionInfoPairs,
  VehicleInfo,
  Warranty,
} from '@rabbit/data/types';
import {
  BPIWarranty_WarrantyType,
  RegisterWarranty_ConsumerInfo,
  RegistrationsWarrantyOptionShape,
} from '@rabbit/elements/shared-types';
import { RISK_CATEGORY_MAP } from '@rabbit/sage/utils/consts';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { useTranslation } from 'react-i18next';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { capitalize } from 'radash';

export interface CoverageOptions {
  warranty_type: string;
  warranty_duration: any;
  warranty_claim_limit: any;
  warranty_labour_rates: any;
  warranty_offer?: DTWarranty_Offer;
  warranty_template: DTWarranty_Template;
  warranty_start_date?: string;
}

export interface EditWarrantyOfferDetailsProps {
  handleClose: () => void;
  warranty: DTWarranty;
  consumer: RegisterWarranty_ConsumerInfo;
  productInfo: VehicleInfo;
}

export function EditWarrantyOfferDetails({
  handleClose,
  warranty,
  consumer,
  productInfo,
}: EditWarrantyOfferDetailsProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const termRef = useRef(null) as any;
  const claimLimitRef = useRef(null) as any;

  const { getApprovedWarrantyOffers, registerSRVHoldingWithWarranty, isReady } =
    useSageAPI();

  const partnerRetailerLink = useMemo(() => {
    const repairerPrefix =
      PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter;
    const retailerPrefix =
      PersonaTypeSingleLetter.Retailer + PersonaIdTypeSplitter;
    return warranty?.retailerLink
      ?.replace(repairerPrefix, '')
      .replace(retailerPrefix, '');
  }, [warranty?.retailerLink]);

  const [offers, setOffers] = useState<
    RegistrationsWarrantyOptionShape[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const findOfferByPlan = (plan: string) => {
    return offers?.find((offer) => offer.offer?.templateLink === plan);
  };

  const [selectedOffer, setSelectedOffer] = useState<
    RegistrationsWarrantyOptionShape | undefined
  >(findOfferByPlan(warranty?.templateLink ?? ''));
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput | null>(
    null
  );

  const [labourRates, setLabourRates] =
    useState<PartnerSettings['labourRates']>();

  const initialValues: any = {
    plan: warranty?.templateLink ?? '',
    term: warranty?.duration ?? '',
    claimLimit:
      warranty?.appliedOptions.find(
        (option: any) => option.key === 'claimLimit'
      )?.value ?? '',
    startDate: warranty?.startDate ?? '',
    labourRate:
      getIncreasedLabourRateValue(
        warranty as unknown as Warranty
      )?.toString() ?? '',
  };

  const [formValues, setFormValues] = useState<any>(initialValues);

  const validationSchema = Yup.object().shape({
    plan: Yup.string().required('Required'),
    term: Yup.string().required('Required'),
    claimLimit: Yup.string().required('Required'),
    labourRate: Yup.string().required('Required'),
  });

  const OPTIONS_PLANS = offers?.map((plan) => ({
    id: plan.key,
    label: plan.offer?.title ?? '',
    value: plan.offer?.templateLink ?? '',
  }));

  const getOptionsFromSelectedOffer = (key: string) => {
    return selectedOffer?.approvedOptions
      .find((i: any) => i.label === key)
      ?.optionInfo.map((obj: any) => ({
        id: obj.option.value,
        label: obj.option.label,
        value: obj.option.value,
        availablePairs: obj.availablePairs,
      }));
  };

  const OPTIONS_TERMS = getOptionsFromSelectedOffer('Duration');
  //const OPTIONS_CLAIM_LIMITS = getOptionsFromSelectedOffer('Claim limit');
  let findOptionTerms = OPTIONS_TERMS?.find(
    (option: any) => option.value === formValues.term
  );
  if (!findOptionTerms?.availablePairs && OPTIONS_TERMS && OPTIONS_TERMS[0]) {
    findOptionTerms = OPTIONS_TERMS[0];
  }
  const OPTIONS_CLAIM_LIMITS = findOptionTerms?.availablePairs
    .find((i: SingleApprovedOptionInfoPairs) => i.label === 'Claim limit')
    .indices?.map((indice: DTOptionsSingleOption, index: number) => ({
      id: index + '',
      label: indice.label,
      value: indice.value,
    }));

  const getFindTerms = (value: string, options: any) => {
    const values = options.find((option: any) => option.value === value);
    if (values) {
      return values?.value;
    } else {
      formikRef?.current?.setFieldValue('term', options[0]['value']);
      return options[0].value;
    }
  };

  const getFindClaimLimit = (value: string, options: any) => {
    const option = options.find((option: any) => option.value === value);
    if (option) {
      return option.value;
    } else {
      formikRef?.current?.setFieldValue('claimLimit', options[0].value);
      return options[0].value;
    }
  };

  const generateLabourRateOption = (index: number, name: string) => {
    if (labourRates === undefined) return null;
    const rate = labourRates?.[name as keyof typeof labourRates] as
      | number
      | undefined;
    if (index === 0) {
      return {
        id: index.toString(),
        label: `Default (£${labourRates?.default ?? 0}/h)`,
        value: labourRates?.default ?? 0,
      };
    }
    if (rate && rate !== 0) {
      return {
        id: index.toString(),
        label: `${capitalize(name)} (£${rate}/h)`,
        value: rate,
      };
    }
    return null;
  };

  const OPTIONS_LABOUR_RATES = labourRates
    ? [
        generateLabourRateOption(0, 'default'),
        generateLabourRateOption(1, 'tier1'),
        generateLabourRateOption(2, 'tier2'),
        generateLabourRateOption(3, 'tier3'),
      ].filter(Boolean)
    : [];

  const onSubmit = async (values: any) => {
    if (
      !deciderOutput ||
      !warranty?.templateLink ||
      !warranty?.docid ||
      !productInfo.regDate
    )
      return;

    const { term, claimLimit, labourRate, startDate } = values;

    const vehicleInfo: VehicleInfo = {
      ...productInfo,
      techCheckDate: productInfo.techCheckDate
        ? getFormattedDate(new Date(productInfo.techCheckDate))
        : '',
      lastServiceDate: productInfo.lastServiceDate
        ? getFormattedDate(new Date(productInfo.lastServiceDate))
        : '',
      regDate: getFormattedDate(new Date(productInfo.regDate)),
    };

    const holding: SRVInfo = {
      type: SRVType.Vehicle,
      productInfo: vehicleInfo,
    };

    const consumerInfo: RegisterWarranty_ConsumerInfo = {
      firstName: consumer?.firstName ?? '',
      lastName: consumer?.lastName ?? '',
      consumerEmail: consumer?.consumerEmail ?? '',
      phoneNumber: consumer?.phoneNumber ?? '',
    };
    if (consumer?.address) consumerInfo.address = consumer?.address;

    const updatedWarranty: BPIWarranty_WarrantyType = {
      startDate: startDate ? new Date(startDate) : null,
      options: [
        {
          key: 'duration',
          value: term,
        },
        {
          key: 'claimLimit',
          value: claimLimit,
        },
        {
          key: 'increasedLabourRate',
          value: labourRate,
        },
      ],
    };

    try {
      setIsSubmitting(true);
      const { warrantyLink, holdingLink } =
        await registerSRVHoldingWithWarranty({
          warrantor: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          holding,
          templateLink: selectedOffer?.template.docid || warranty?.templateLink,
          consumer: consumerInfo,
          warranty: updatedWarranty,
          deciderOutput, // Remove the undefined assignment
          offerLink: selectedOffer?.offer?.docid,
          warrantyLink: warranty?.docid,
        });

      if (warrantyLink) {
        toast.success('Warranty updated successfully');
        setIsSubmitting(false);
        handleClose();
        // Reload the page after 1 second - Ugly hack to refresh the page after the warranty is updated -VP
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e: any) {
      console.error(e);
      setIsSubmitting(false);
      toast.error('Failed to update warranty.');
      return;
    }
  };

  const onValueChanged = (values: any) => {
    if (values.plan !== '') {
      setSelectedOffer(findOfferByPlan(values.plan));
    }

    if (values.term !== formValues.term) claimLimitRef?.current?.clearValue();
    setFormValues(values);
  };

  useEffect(() => {
    if (offers && warranty?.templateLink) {
      setSelectedOffer(findOfferByPlan(warranty?.templateLink));
    }
  }, [offers, warranty?.templateLink]);

  useEffect(() => {
    if (isReady) {
      void (async () => {
        if (
          !partnerRetailerLink ||
          !productInfo?.regDate ||
          !productInfo?.vehicleCategory ||
          !productInfo?.mileage ||
          !productInfo.mileageUnit
        )
          return; // todo: remove this once the retailerLink is passed in

        const convertedMileage = getConvertedMileage(
          productInfo?.mileage,
          productInfo?.mileageUnit
        );

        //KEEP FOR DEBUGGING -VP
        console.debug('OFFER age', getAgeFromDate(productInfo.regDate));
        console.debug('OFFER mileage', Number(convertedMileage));
        console.debug(
          'OFFER riskCategory',
          RISK_CATEGORY_MAP[productInfo.vehicleCategory]
        );
        console.debug('OFFER partnerTenantLink', partnerRetailerLink);
        console.debug(
          'OFFER warrantorLink',
          getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          )
        );

        try {
          const { offers, labourRates } = await getApprovedWarrantyOffers(
            // Add all the relevant data you have at this point here. For a list of all the fields to fill in, check out the decider for these templates
            // I'll make something to fetch the stipulations from the decider when it becomes necessary, but for now look for  PinnacleDecider2 in
            // libs/bizproc/core/src/decider/testing/testdata.ts
            {
              params: {
                age: getAgeFromDate(productInfo.regDate),
                mileage: Number(convertedMileage),
                riskCategory: RISK_CATEGORY_MAP[productInfo.vehicleCategory],
              },
              // Dimensions will be the options that we want to display in the next step, in this case it's the duration and claim limit  (ignore labour rates for now)
              dimensions: ['claimLimit', 'duration'],
              partnerTenantLink: partnerRetailerLink,
              warrantorLink: getRootPersonaFromLexicon(
                t(PrincipalsFieldName),
                PersonaTypeSingleLetter.Warrantor
              ),
            }
          );

          //KEEP FOR DEBUGGING -VP
          console.debug('OFFER offers', offers);

          setOffers(offers);
          setLabourRates(labourRates);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [partnerRetailerLink, isReady]);

  /* -------------------------- Perform the decision -------------------------- */
  useEffect(() => {
    if (
      !selectedOffer?.offer ||
      !selectedOffer?.template ||
      !OPTIONS_LABOUR_RATES.length ||
      !productInfo?.regDate ||
      !productInfo?.vehicleCategory ||
      !productInfo?.mileage ||
      !productInfo?.mileageUnit ||
      !formikRef?.current?.values?.claimLimit ||
      !formikRef?.current?.values?.term
    )
      return;

    const productAge = getAgeFromDate(productInfo?.regDate);
    const convertedMileage = getConvertedMileage(
      productInfo?.mileage,
      productInfo?.mileageUnit
    );

    let claimLimitLabel = getLabelByValue(
      formikRef?.current?.values?.claimLimit,
      OPTIONS_CLAIM_LIMITS
    );
    if (!claimLimitLabel) {
      // if claim limit is not found in approved options, fetch it from template optionList
      const templateOptions = findOfferByPlan(warranty?.templateLink)?.template
        .options;
      const formattedOptions =
        formatTemplateOptions(templateOptions)['claimLimit'];
      claimLimitLabel = getLabelByValue(
        formikRef?.current?.values?.claimLimit,
        formattedOptions
      );
    }
    
    const claimLimitValue = getFindClaimLimit(
      getLabelByValue(
        formikRef?.current?.values?.claimLimit,
        OPTIONS_CLAIM_LIMITS
      ) !== 'PPOV'
        ? Number(formikRef?.current?.values?.claimLimit)
        : formikRef?.current?.values?.claimLimit ?? '',
      OPTIONS_CLAIM_LIMITS
    );

    let term = getFindTerms(formikRef?.current?.values?.term, OPTIONS_TERMS);

    const duration = {
      label: getLabelByValue(term, OPTIONS_TERMS)
        .split(' ')
        .map((i: any) => toTitleCase(i))
        .join(' '),
      value: term,
    };
    
    const claimLimit = {
      label: claimLimitLabel,
      value: claimLimitValue,
    };

    const stipulated = {
      age: productAge,
      mileage: convertedMileage,
      riskCategory: RISK_CATEGORY_MAP[productInfo?.vehicleCategory],
      duration,
      claimLimit,
      isCommercial: productInfo?.isCommercial ?? false,
      is4x4: productInfo?.driveType === DRIVE_TYPE_4X4.value, //'4x4',
      isElectric: productInfo?.fuel === CAR_FUEL_ELECTRIC.value, //'Electric',
      isHybrid: productInfo?.fuel === CAR_FUEL_HYBRID.value, //'Hybrid',
      increasedLabourRate1:
        getIDByValue(
          formikRef?.current?.values?.labourRate,
          OPTIONS_LABOUR_RATES
        ) === '1',
      increasedLabourRate2:
        getIDByValue(
          formikRef?.current?.values?.labourRate,
          OPTIONS_LABOUR_RATES
        ) === '2',
      increasedLabourRate3:
        getIDByValue(
          formikRef?.current?.values?.labourRate,
          OPTIONS_LABOUR_RATES
        ) === '3',
    };

    const output = DeciderPerformDecision(
      stipulated,
      selectedOffer?.template,
      selectedOffer?.offer
    );

    setDeciderOutput(output);
  }, [selectedOffer?.template, selectedOffer?.offer, formValues]);

  if (!offers) return <LoadingSpinner size="xs" />;

  if (offers && offers.length === 0)
    return (
      <div>
        No offers based on current criteria. Please review details on the
        previous step.
      </div>
    );

  const currentDecided = warranty.decider?.decided;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formikRef}
      validate={onValueChanged}
    >
      {({ values, errors }) => (
        <Form>
          <div className="flex flex-col gap-4 ">
            {offers && offers?.length > 0 && (
              <>
                <div className="flex gap-4">
                  <Input
                    type="select"
                    name="plan"
                    label="Plan*"
                    settings={{
                      id: 'plan',
                      placeholder: 'Please select a plan',
                      options: OPTIONS_PLANS,
                    }}
                  />
                  <Input
                    type="select"
                    name="term"
                    label="Term*"
                    settings={{
                      id: 'term',
                      placeholder: 'Please select a term',
                      options: OPTIONS_TERMS ?? [],
                      ref: termRef,
                    }}
                  />
                </div>
                <div className="flex gap-4">
                  <Input
                    type="select"
                    name="claimLimit"
                    label="Claim limit*"
                    settings={{
                      id: 'claimLimit',
                      placeholder: 'Please select a claim limit',
                      options: OPTIONS_CLAIM_LIMITS ?? [],
                      ref: claimLimitRef,
                      disabled: values.term === '',
                    }}
                  />
                  <Input
                    type="select"
                    name="labourRate"
                    label="Labour rate*"
                    settings={{
                      id: 'labourRate',
                      placeholder: 'Please select a labour rate',
                      options: OPTIONS_LABOUR_RATES.length
                        ? OPTIONS_LABOUR_RATES.map((option) => ({
                            ...option,
                            value: option?.value ? option.value.toString() : '',
                          }))
                        : [],
                    }}
                  />
                </div>
                <div>
                  <Input
                    type="datepicker"
                    name="startDate"
                    label="Start date"
                    settings={{
                      id: 'startDate',
                      placeholder: 'Enter start date here',
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  {currentDecided && (
                    <div className="font-nunito flex text-base font-bold text-black">
                      Current price:{' '}
                      {currentDecided.approval
                        ? getCurrencyFormat(
                            String(currentDecided.warrantyPrice), // Convert to string
                            appInfo.currency
                          ) +
                          ` (${getCurrencyFormat(
                            Number(currentDecided.warrantyPrice) + // Convert to number
                              Number(currentDecided.VAT), // Convert to number
                            appInfo.currency
                          )} incl. VAT)`
                        : '-'}
                    </div>
                  )}
                  {deciderOutput && (
                    <div className="font-nunito flex text-lg font-bold text-black">
                      New price:{' '}
                      {deciderOutput.decided.approval &&
                      deciderOutput.decided.warrantyPrice
                        ? getCurrencyFormat(
                            String(deciderOutput.decided.warrantyPrice), // Convert to string
                            appInfo.currency
                          ) +
                          ` (${getCurrencyFormat(
                            Number(deciderOutput.decided.warrantyPrice) + // Convert to number
                              Number(deciderOutput.decided.VAT), // Convert to number
                            appInfo.currency
                          )} incl. VAT)`
                        : '-'}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="flex gap-4">
              <Button
                kind="primary"
                type="submit"
                className="bg-primary-600"
                loading={isSubmitting}
                disabled={
                  deciderOutput?.decided.approval === false || isSubmitting
                }
              >
                Save
              </Button>
              <Button
                kind="red"
                type="button"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditWarrantyOfferDetails;

const getLabelByValue = (value: string, options: any) => {
  const values = options.find((option: any) => option.value === value);
  return values?.label ?? options[0].label;
};

const getIDByValue = (value: string, options: any) => {
  return options.find((option: any) => String(option.value) === String(value))
    ?.id;
};

/**
 * Formats template options by processing the optionList and its items.
 * @param {Object} options - The options object containing the optionList.
 * @returns {Object} An object with formatted options.
 */
function formatTemplateOptions(options: any) {
  // Check if options and optionList exist
  if (!options || !options.optionList) {
    console.warn('Invalid options structure');
    return {};
  }

  return options.optionList.reduce((acc: any, option: any) => {
    // Check if optionItems exist for the current option
    if (!option.optionItems) {
      console.warn(`No optionItems for ${option.optionKey}`);
      acc[option.optionKey] = [];
      return acc;
    }

    // Process each option item
    acc[option.optionKey] = option.optionItems.map((item: any) => {
      // Remove any non-alphanumeric characters from the item value like currency symbol
      // This allows values like 'P3M' or '500' to be preserved
      const itemValue = item.itemValue.replace(/[^a-zA-Z0-9]/g, '');

      return {
        id: itemValue,
        label: item.itemLabel,
        // If itemValue is not a number (e.g., 'P3M'), keep it as a string
        // If it is a number (e.g., '500'), convert it to a Number
        value: isNaN(itemValue) ? itemValue : Number(itemValue),
        availablePairs: [],
      };
    });
    return acc;
  }, {});
}