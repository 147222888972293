import { useState } from 'react';
import { Modal } from '@rabbit/elements/shared-components';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { useTranslation } from 'react-i18next';
import {
  RenderWarrantyIrelandFlow,
  RenderNuCoverFlow,
  RenderPinnacleWarrantiesFlow,
  RenderGenericFlow,
} from '../registration-flows/TenantFlows';

export interface ModalNewRegistrationProps {
  itemDetails?: any; //TODO
  handleClose: () => void;
}

export interface CarRegistrationShape {
  registrationNo: string;
  cherishedRegistrationNo?: string;
  make: string;
  model: string;
  version: string;
  body: string;
  regDate: string;
  engineCc: string;
  colour: string;
  fuel: string;
  transmission: string;
  yearOfManufacture: string;
  chassisNo: string;
  engineNo: string;
  vehicleCategory: string;
  manualInput: boolean;
}

function ModalNewRegistration({ handleClose }: ModalNewRegistrationProps) {
  const [currentStep, setCurrentStep] = useState<{
    step: number;
    data: any;
  }>({ step: 0, data: null });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const tenantLink = t('tenantLink');
  const tenantConfigType = t('tenantConfigType');

  let tenantType: TenantType = 'defaultTenants';
  if (tenantConfigType === 'vehicle') {
    tenantType = 'carTenants';
  } else if (tenantConfigType === 'stone') {
    tenantType = 'tableTenants';
  }

  const stepTitle: { [key: number]: string } = {
    0: t('general.newRegistration'),
    1:
      tenantType === 'carTenants'
        ? 'Car details'
        : tenantType === 'defaultTenants'
        ? t('general.createNewUser')
        : t('general.coverageDetails'),
    2:
      tenantType === 'carTenants'
        ? 'Car details'
        : tenantType === 'defaultTenants'
        ? t('general.completeRegistration')
        : t('general.consumerDetails'),
    3:
      tenantType === 'carTenants'
        ? t('general.coverageOptions')
        : tenantType === 'defaultTenants'
        ? t('general.addNewProduct')
        : t('general.summary'),
    4: t('general.consumerDetails'),
    5: t('general.summary'),
  };

  const modalSettings: ModalSettingsShape = {
    title: stepTitle[currentStep.step],
    handleClose: handleClose,
    childrenClassName:
      tenantType === 'defaultTenants' &&
      currentStep.step === 3 &&
      stepTitle[currentStep.step] === t('general.addNewProduct')
        ? 'overflow-auto'
        : tenantType === 'defaultTenants' && currentStep.step !== 1
        ? 'overflow-visible'
        : currentStep.step === 0 ||
          (currentStep.step === 3 && stepTitle[currentStep.step] !== 'Summary')
        ? 'overflow-auto'
        : 'overflow-auto',
    handleBackPress:
      currentStep.step > 0
        ? () =>
            tenantType === 'defaultTenants'
              ? onChangeStep(0)
              : onChangeStep(currentStep.step - 1)
        : undefined,
  };

  const onChangeStep = (step_in: number, data: any = null) => {
    if (step_in === 0) return setCurrentStep({ step: step_in, data: {} });
    setCurrentStep({
      ...currentStep,
      step: step_in,
      data: Object.assign(currentStep.data || {}, data),
    });
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      isLoading={isLoading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      {tenantType === 'defaultTenants' && (
        <RenderGenericFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'WARRANTYIRELAND' && (
        <RenderWarrantyIrelandFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'NUCOVER' && (
        <RenderNuCoverFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
      {tenantLink === 'PINNACLEWARRANTIES' && (
        <RenderPinnacleWarrantiesFlow
          onChangeStep={onChangeStep}
          currentStep={currentStep}
          handleClose={handleClose}
          setIsLoading={setIsLoading}
          tenantType={tenantType}
        />
      )}
    </Modal>
  );
}

export default ModalNewRegistration;
